import fetcher from "core/utils/fetcher";
import { motion } from "framer-motion";
import useSWR from "swr";

export const HealthReport = ({ poll }) => {
  const { data } = useSWR(poll ? "/api/util/health" : null, fetcher, {
    refreshInterval: 1000,
  });

  return poll ? (
    <div className="fixed bottom-1 left-0 right-0 z-50 mx-auto flex h-12 w-[600px] items-center justify-start rounded-lg bg-[#222] text-center text-sm text-[#f9f9f9] shadow-md">
      <div className="relative flex items-center">
        <div className="w-[150px] flex-shrink-0 text-center leading-tight">
          <div className="flex items-center justify-center space-x-1">
            <motion.div className="font-bold tabular-nums" animate={{ scale: [1, 1.1, 1] }} key={data?.memory?.rss?.percent}>
              {data?.memory?.rss?.formatted || 0}
            </motion.div>
            <motion.div className="text-xs font-normal" animate={{ scale: [1, 1.1, 1] }} key={data?.memory?.usage?.percent}>
              ({data?.memory?.usage.percent || 0})
            </motion.div>
          </div>
          <div className="mt-px text-[11px] font-medium opacity-60">Memory Usage</div>
        </div>
        <div className="w-[120px] flex-shrink-0 text-center leading-tight">
          <motion.div className="font-bold tabular-nums" animate={{ scale: [1, 1.1, 1] }} key={data?.freeMemory?.formatted}>
            {data?.freeMemory?.formatted || 0} / {data?.totalMemory?.formatted || 0}
          </motion.div>
          <div className="mt-px text-[11px] font-medium opacity-60">Free / Total</div>
        </div>
        <div className="h-12 border-l border-[#333]"></div>
        <div className="w-[90px] flex-shrink-0 text-center leading-tight">
          <motion.div className="font-bold tabular-nums" animate={{ scale: [1, 1.1, 1] }} key={data?.cpu?.usage?.percent}>
            {data?.cpu?.usage?.percent || 0}
          </motion.div>
          <div className="mt-px text-[11px] font-medium opacity-60">CPU Usage</div>
        </div>
        <div className="h-12 border-l border-[#333]"></div>
        <div className="w-[130px] flex-shrink-0 text-center leading-tight">
          <div className="font-bold tabular-nums">{data?.uptime?.formatted || 0}</div>
          <div className="mt-px text-[11px] font-medium opacity-60">Uptime</div>
        </div>
        <div className="h-12 border-l border-[#333]"></div>
        <div className="w-[100px] flex-shrink-0 text-center leading-tight">
          <div className="font-bold tabular-nums">{data?.version?.value?.replace("v", "") || <span>&nbsp;</span>}</div>
          <div className="mt-px text-[11px] font-medium opacity-60">Node Version</div>
        </div>
      </div>
    </div>
  ) : null;
};
