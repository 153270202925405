import { Button, DropdownMenu, Input, Popover, Prompt, SelectMenu, Tabs, Tooltip, classNames, useToast } from "@mojoactive/components";
import {
  IconDeviceFloppy,
  IconDotsVertical,
  IconFile,
  IconFileCode,
  IconFileSearch,
  IconFileTypeCsv,
  IconFileTypeDoc,
  IconFileTypePdf,
  IconFileTypePpt,
  IconFileTypeXls,
  IconFiles,
  IconFilter,
  IconLayoutGrid,
  IconLayoutList,
  IconPhoto,
  IconRefresh,
  IconSortAscending,
  IconSortAscendingLetters,
  IconSortAscendingNumbers,
  IconSortDescending,
  IconSortDescendingLetters,
  IconSortDescendingNumbers,
  IconTrash,
  IconVideo,
} from "@tabler/icons-react";
import AdminTable from "core/admin/AdminTable";
import { useStore } from "core/hooks/useStore";
import { Formatters, Generators } from "core/utils/utilities";
import { format } from "date-fns";
import { omit, orderBy, startCase } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useClickAway } from "react-use";
import { useFileManager } from ".";
import Loading from "../Common/Loading";

import { DonutChart, Legend } from "@tremor/react";
import classnames from "core/utils/classnames";

export default function FileManager({ mode }) {
  const { files, initialLoading, loading, refresh, categories, filters, setFilters } = useFileManager();
  const [query, setQuery] = useState("");
  const [layout, setLayout] = useState("grid");
  const [sort, setSort] = useState("lastModified:desc");
  const [selected, setSelected] = useState(null);

  const results = useMemo(() => {
    let filtered = query ? files?.filter((o) => o.filename.toLowerCase().includes(query.toLowerCase())) : files;
    filtered = sort ? orderBy(filtered, (o) => o[sort.split(":")[0]], sort.split(":")[1]) : filtered;

    if (filters) {
      filtered = filtered?.filter((o) =>
        Object.keys(filters)
          .filter((key) => filters[key])
          .every((key) => o.metadata?.[key] === filters[key])
      );
    }

    return filtered;
  }, [files, query, sort, filters]);

  const activeFilters = useMemo(() => {
    return Object.keys(filters).filter((key) => filters[key]);
  }, [filters]);

  const isModal = mode === "modal";

  return (
    <div className={classNames("relative", isModal ? "-mx-6 mb-6" : "")}>
      <div className={classNames("flex justify-between space-x-2 pt-2", isModal ? "px-6" : "")}>
        <div className="flex-1">
          <Input value={query} onChange={(e) => setQuery(e)} placeholder="Search" />
        </div>

        <Filters active={activeFilters} categories={categories} sort={sort} setSort={setSort} filters={filters} setFilters={setFilters} />

        <LayoutButtons layout={layout} setLayout={setLayout} />

        <Stats />

        <button
          type="button"
          className="relative inline-flex h-[38px] items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          onClick={() => refresh()}
        >
          <IconRefresh size={19} />
        </button>
      </div>

      <div
        className={classNames(
          "w-full flex-1",
          isModal ? "" : layout !== "list" ? "relative mt-4 overflow-hidden rounded-md border bg-white" : "relative mt-6 overflow-hidden rounded-md"
        )}
      >
        <div className={classnames("relative")}>
          <div
            style={{ height: isModal ? `calc(60vh - 38px)` : "auto" }}
            className={classNames(isModal ? "overflow-auto px-6 py-4" : layout !== "list" ? "p-6" : "")}
          >
            {initialLoading ? (
              <Loading show={initialLoading} />
            ) : (
              <div>
                <Loading show={loading} cover />

                {layout === "list" && results?.length > 0 && (
                  <AdminTable
                    data={results}
                    emptyMessage="No files found."
                    columns={[
                      { field: "filename", label: "Name" },
                      { field: "category", label: "Category" },
                      { field: "size", label: "Size" },
                      { field: "lastModified", label: "Modified" },
                    ]}
                    onClick={(row) => setSelected(row)}
                    transform={(field, value, row) => {
                      const category = row.metadata?.category;
                      const [r, g, b] = category ? Generators.HexToRGB(Generators.HexFromString(category || "")) : [];

                      switch (field) {
                        case "filename":
                          return <Filename file={row} />;
                        case "size":
                          return Formatters.PrettyBytes(row.size, 1);
                        case "category":
                          return (
                            row?.metadata?.category && (
                              <span
                                className={classnames("rounded-lg px-2 py-1 text-xs font-semibold")}
                                style={{
                                  backgroundColor: `rgba(${r}, ${g}, ${b}, 0.2)`,
                                  color: `rgba(${r}, ${g}, ${b}, 1)`,
                                }}
                              >
                                {row.metadata.category}
                              </span>
                            )
                          );
                        case "lastModified":
                          return format(new Date(row.lastModified), "MM/dd/yy hh:mm a");
                        default:
                          return value;
                      }
                    }}
                  />
                )}

                {layout === "grid" && (
                  <ul className="grid grid-cols-2 gap-x-4 gap-y-6 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-6">
                    {results?.map((o, i) => (
                      <GridItem key={i} file={o} onClick={setSelected} />
                    ))}
                  </ul>
                )}

                {(!results || results?.length === 0) && (
                  <div className="flex flex-col items-center justify-center bg-white p-10 text-gray-500">
                    <IconFileSearch className="h-8 w-8" />
                    <p className="mt-2 text-sm font-medium">No results founds</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {selected && <FileSelected file={selected} onClose={setSelected} mode={mode} />}
      </div>
    </div>
  );
}

const Stats = () => {
  const { stats } = useFileManager();

  return (
    <Popover
      button={
        <button
          type="button"
          className="relative inline-flex h-[38px] items-center divide-x rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          <Tooltip content="Total Files (click for details)" placement="top">
            <span className="flex px-3 py-2">
              <IconFiles size={19} className="mr-1.5" />
              {stats?.count || 0} Files
            </span>
          </Tooltip>

          <Tooltip content="Total Size (click for details)" placement="top">
            <span className="flex px-3 py-2">
              <IconDeviceFloppy size={19} className="mr-1.5" />
              {Formatters.PrettyBytes(stats?.size || 0, 0)}
            </span>
          </Tooltip>
        </button>
      }
    >
      <div className="flex flex-col items-center justify-center pt-1 text-gray-800">
        <div className="mb-6 flex w-full flex-col space-y-2">
          <div className="w-full rounded-lg border bg-white py-2 text-center">
            <div className="text-sm font-medium text-gray-700">Total Files</div>
            <div className="mt-1 text-xl font-bold">{stats?.count || 0}</div>
          </div>

          <div className="w-full rounded-lg border bg-white py-2 text-center">
            <div className="text-sm font-medium text-gray-700">Total Size</div>
            <div className="mt-1 text-xl font-bold">{stats?.size ? Formatters.PrettyBytes(stats?.size, 0) : 0}</div>
          </div>
        </div>

        <div className="w-[250px] text-center">
          <DonutChart
            data={stats?.extensions || []}
            width={200}
            category="size"
            index="name"
            variant="pie"
            valueFormatter={(value) => Formatters.PrettyBytes(value, 0)}
            colors={["green", "violet", "indigo", "rose", "cyan", "amber", "blue", "fuchsia", "lime", "orange", "purple", "teal"]}
          />
          <div className="mt-1 flex">
            <Legend
              categories={stats?.extensions?.map((o) => o.name) || []}
              colors={["green", "violet", "indigo", "rose", "cyan", "amber", "blue", "fuchsia", "lime", "orange", "purple", "teal"]}
            />
          </div>
        </div>
      </div>
    </Popover>
  );
};

const Filters = ({ active, categories, sort, setSort, filters, setFilters }) => {
  const otherFilters = Object.keys(filters)
    .filter((key) => key !== "category")
    .filter((key) => filters[key]);

  return (
    <Popover
      button={
        <button
          type="button"
          className="relative inline-flex h-[38px] items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          <IconFilter size={19} />
          {active.length > 0 && (
            <span>
              <span className="absolute right-0.5 top-0.5 z-10 inline-flex -translate-y-1/2 translate-x-1/2 transform items-center justify-center rounded-full bg-orange-500 px-2 py-1 text-xs font-bold leading-none text-orange-100">
                {active.length}
              </span>
            </span>
          )}
        </button>
      }
    >
      <div className="-mx-[9px] -my-[5px] w-[285px] text-gray-700">
        <Tabs
          className="px-4"
          panelClassName="px-4 py-2"
          items={[
            {
              name: "Filter",
              content: (
                <div className="py-2">
                  <SelectMenu
                    label="Category"
                    options={[{ text: "Show All", value: "" }].concat(categories.map((o) => ({ text: o, value: o })))}
                    value={filters.category}
                    onChange={(item) => setFilters({ ...filters, category: item.value ? item.value : "" })}
                  />

                  {otherFilters.length > 0 && (
                    <div className="mt-2">
                      <div className="py-2">
                        <div className="text-sm font-medium">Inherited Filters</div>
                      </div>
                      <div className="divide-y border-t">
                        {otherFilters.map((key, index) => (
                          <div key={index} className="flex items-center justify-between py-2">
                            <div className="truncate pr-4">
                              <div className="truncate text-sm capitalize">{startCase(key)}</div>
                              <div className="truncate text-xs capitalize text-gray-500">{startCase(filters[key])}</div>
                            </div>
                            <button
                              type="button"
                              className="relative -ml-px inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                              onClick={() => setFilters({ ...filters, [key]: null })}
                            >
                              <IconTrash className="h-4 w-4" />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ),
            },
            {
              name: "Sort",
              content: (
                <>
                  <SortBy label="Name" sortKey="filename" sort={sort} setSort={setSort} />
                  <SortBy label="Size" sortKey="size" sort={sort} setSort={setSort} />
                  <SortBy label="Modified" sortKey="lastModified" sort={sort} setSort={setSort} />
                </>
              ),
            },
          ]}
        ></Tabs>
      </div>
    </Popover>
  );
};

const Filename = ({ file }) => {
  return file.metadata?.name || file.filename;
};

const GridItem = ({ file, onClick }) => {
  const category = file.metadata?.category;
  const [r, g, b] = category ? Generators.HexToRGB(Generators.HexFromString(category || "")) : [];

  return (
    <li className="relative w-full" onClick={() => onClick(file)}>
      <div className="group flex h-32 w-full items-center justify-center overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-orange-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 hover:border hover:shadow-sm">
        <span className="pointer-events-none object-cover group-hover:text-orange-500">
          <FileManagerItemPreview file={file} />
        </span>
        <button type="button" className="absolute inset-0 focus:outline-none">
          <span className="sr-only">View details for {file.filename}</span>
        </button>

        {file?.metadata?.category && (
          <span
            className={classnames("absolute right-1.5 top-1.5 rounded-lg px-2 py-1 text-xs font-semibold")}
            style={{
              backgroundColor: `rgba(${r}, ${g}, ${b}, 0.2)`,
              color: `rgba(${r}, ${g}, ${b}, 1)`,
            }}
          >
            {file.metadata.category}
          </span>
        )}
      </div>

      <p className="pointer-events-none mb-1 mt-2 block truncate text-sm font-semibold text-gray-900">
        <Filename file={file} />
      </p>
      <div className="flex justify-between text-[13px]">
        <p className="block text-gray-500">Size</p>
        <p className="block font-medium tabular-nums text-gray-500">{Formatters.PrettyBytes(file.size, 1)}</p>
      </div>
      <div className="flex justify-between text-[13px]">
        <p className="block text-gray-500">Modified</p>
        <p className="block font-medium tabular-nums text-gray-500">{format(new Date(file.lastModified), "MM/dd/yy hh:mm a")}</p>
      </div>
    </li>
  );
};

const FileSelected = ({ file, mode, onClose }) => {
  const { refresh } = useFileManager();
  const { api } = useStore();
  const Toast = useToast();
  const modalRef = useRef(null);
  useClickAway(modalRef, () => onClose(null));

  const isModal = mode === "modal";
  const category = file.metadata?.category;
  const [r, g, b] = category ? Generators.HexToRGB(Generators.HexFromString(category || "")) : [];

  const handleDownload = () => {
    window.open(`/api/store/file-manager?filename=${file.filename}&download=true`, "_blank");
  };

  const handleDelete = async () => {
    const proceed = await Prompt.Confirm();
    if (!proceed) return;

    try {
      await api.delete(`/api/store/file-manager?filename=${file.filename}`);
      Toast.Success({ title: "File Deleted" });
      refresh();
      onClose(null);
    } catch (ex) {
      Toast.Error({ title: "Error Deleting File" });
    }
  };

  const isSameName = file.metadata?.name === file.filename.split("/").pop();

  return (
    <div className={classNames("absolute left-0 top-0 z-20 h-full w-full", isModal && "border-t")}>
      <div className="z-30 flex h-full w-full cursor-pointer bg-white/50 backdrop-blur-sm" onClick={() => onClose(null)}></div>
      <div className="absolute right-0 top-0 z-40 h-full w-1/2 overflow-auto border-l bg-white p-4 text-sm shadow-sm" ref={modalRef}>
        <div className="relative flex h-32 w-full items-center justify-center overflow-hidden rounded-lg bg-gray-100">
          <span className="pointer-events-none object-cover">
            <FileManagerItemPreview file={file} />
          </span>

          {file?.metadata?.category && (
            <span
              className={classnames("absolute right-1.5 top-1.5 rounded-lg px-2 py-1 text-xs font-semibold")}
              style={{
                backgroundColor: `rgba(${r}, ${g}, ${b}, 0.2)`,
                color: `rgba(${r}, ${g}, ${b}, 1)`,
              }}
            >
              {file.metadata.category}
            </span>
          )}
        </div>

        <div className="mt-3 flex items-center justify-between space-x-3">
          <Button primary className="w-full" onClick={handleDownload}>
            Download
          </Button>
          <DropdownMenu
            options={[
              {
                text: (
                  <span className="flex items-center font-medium text-red-500">
                    <IconTrash className="mr-2 h-4 w-4" /> Delete
                  </span>
                ),
                onClick: handleDelete,
              },
            ]}
          >
            <span className="relative -ml-px inline-flex h-[38px] items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <IconDotsVertical className="h-4 w-4" />
            </span>
          </DropdownMenu>
        </div>

        <div className="mt-6 divide-y">
          <div className="pb-3 font-semibold text-gray-700">Details</div>
          {file.metadata?.name && (
            <div className="flex items-center justify-between py-3">
              <div className="text-gray-500">Name</div>
              <div className="font-medium text-gray-800">{file.metadata?.name}</div>
            </div>
          )}
          {!isSameName && (
            <div className="flex items-center justify-between py-3">
              <div className="text-gray-500">Filename</div>
              <div className="font-medium text-gray-800">{file.filename.split("/").pop()}</div>
            </div>
          )}
          <div className="flex items-center justify-between py-3">
            <div className="text-gray-500">Size</div>
            <div className="font-medium text-gray-800">{Formatters.PrettyBytes(file.size)}</div>
          </div>
          <div className="flex items-center justify-between py-3">
            <div className="text-gray-500">Last Modified</div>
            <div className="font-medium text-gray-800">{format(new Date(file.lastModified), "M/d/yy h:mm a")}</div>
          </div>
          <div className="pb-3 pt-10 font-semibold text-gray-700">Metadata</div>
          {Object.keys(omit(file.metadata || {}, ["name"])).map((key, index) => (
            <div key={index} className="flex items-center justify-between py-3">
              <div className="capitalize text-gray-500">{key}</div>
              <div className="font-medium text-gray-800">{file.metadata[key]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const LayoutIcons = {
  grid: IconLayoutGrid,
  list: IconLayoutList,
};

const LayoutButtons = ({ layout, setLayout }) => {
  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      {Object.keys(LayoutIcons).map((key, index) => {
        const Icon = LayoutIcons[key];
        return (
          <button
            key={key}
            type="button"
            className={classNames(
              "relative -ml-px inline-flex items-center  bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
              layout === key ? "bg-gray-100" : "",
              index === 0 ? "rounded-l-md" : "rounded-r-md"
            )}
            onClick={() => setLayout(key)}
          >
            <Icon size={19} />
          </button>
        );
      })}
    </span>
  );
};

const SortByIcons = {
  filename: { asc: IconSortAscendingLetters, desc: IconSortDescendingLetters },
  size: { asc: IconSortAscendingNumbers, desc: IconSortDescendingNumbers },
  lastModified: { asc: IconSortAscending, desc: IconSortDescending },
};

const SortBy = ({ label, sortKey, sort, setSort }) => {
  return (
    <div className="flex items-center justify-between py-1">
      <span
        className={classNames("text-sm font-medium", sort === `${sortKey}:asc` || sort === `${sortKey}:desc` ? "text-gray-900" : "text-gray-400")}
      >
        {label}
      </span>
      <div className="isolate inline-flex rounded-md shadow-sm">
        {["asc", "desc"].map((order, index) => {
          const Icon = SortByIcons[sortKey][order];
          return (
            <button
              key={order}
              className={classNames(
                "relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
                sort === `${sortKey}:${order}` ? "bg-gray-100" : "",
                index === 0 ? "rounded-l-md" : "rounded-r-md"
              )}
              onClick={() => setSort(`${sortKey}:${order}`)}
            >
              <Icon className="h-4 w-4" />
            </button>
          );
        })}
      </div>
    </div>
  );
};

const FileManagerItemPreview = ({ file }) => {
  const ext = file.filename.split(".").pop();
  const className = "w-9 h-9 transition-all ease-in-out duration-100";
  switch (ext) {
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
    case "bmp":
      return <IconPhoto className={className} />;
    case "pdf":
      return <IconFileTypePdf className={className} />;
    case "doc":
    case "docx":
      return <IconFileTypeDoc className={className} />;
    case "xls":
    case "xlsx":
      return <IconFileTypeXls className={className} />;
    case "csv":
      return <IconFileTypeCsv className={className} />;
    case "ppt":
    case "pptx":
      return <IconFileTypePpt className={className} />;
    case "mp4":
    case "mov":
    case "avi":
    case "wmv":
    case "flv":
    case "webm":
      return <IconVideo className={className} />;
    case "json":
    case "xml":
    case "txt":
    case "log":
    case "js":
    case "css":
    case "html":
      return <IconFileCode className={className} />;
    default:
      return <IconFile className={className} />;
  }
};
