import { DataProvider } from "lib/data-provider";
import { combineLineItems, joinLineItems } from "./MerchantCheckoutCommon";
import { useStore } from "core/hooks/useStore";

/**
 * @typedef {Object} MerchantCheckoutUtilsType
 */

export const MerchantCheckoutUtils = (store, data) => {
  const { cart, setCart, checkout, setCheckout, setShipping, setBilling, customer, setOrder, setOrderId, setLoading } = data || {};
  const { api } = useStore();

  const util = {
    customer: {
      get: async (query) => {
        const data = await DataProvider.CustomersV3.CustomersGet(store, query);
        return data?.[0];
      },
      create: async (customer) => {
        const data = await DataProvider.CustomersV3.CustomersPost(store, [customer]);
        return data?.[0];
      },
      update: async (customer) => {
        const data = await DataProvider.CustomersV3.CustomersPut(store, [customer]);
        return data?.[0];
      },
    },
    cart: {
      load: async (checkoutId) => {
        setLoading((prev) => ({ ...prev, cart: true }));
        const data = await DataProvider.Carts.GetACart(store, checkout?.id || checkoutId);
        setCart(data);
        setLoading((prev) => ({ ...prev, cart: false }));
        return data;
      },
      create: async (line_items) => {
        setLoading((prev) => ({ ...prev, cart: true }));
        const data = await DataProvider.Carts.CreateACart(store, { customer_id: customer?.id || 0, line_items: line_items });
        setCart(data);
        setCheckout({ id: data.id });
        await Promise.all([util.cart.load(data.id), util.checkout.load(data.id)]);
      },
      update: async (payload = {}) => {
        setLoading((prev) => ({ ...prev, cart: true }));
        await DataProvider.Carts.UpdateACart(store, checkout?.id, payload);
        await Promise.all([util.cart.load(), util.checkout.load()]);
      },
      addItems: async (line_items) => {
        setLoading((prev) => ({ ...prev, cart: true }));
        await DataProvider.Carts.AddCartLineItem(store, checkout?.id, { line_items });
        await Promise.all([util.cart.load(), util.checkout.load()]);
      },
      updateItem: async (line_item) => {
        setLoading((prev) => ({ ...prev, cart: true }));
        await DataProvider.Carts.UpdateCartLineItem(store, checkout?.id, line_item.line_item.id, line_item);
        await Promise.all([util.cart.load(), util.checkout.load()]);
      },
      removeItems: async (line_items) => {
        setLoading((prev) => ({ ...prev, cart: true }));
        for (const line_item of line_items) {
          await DataProvider.Carts.DeleteCartLineItem(store, checkout?.id, line_item.id);
        }

        // determine if the cart is empty
        // if it is, BigCommerce will delete the cart
        // we need to handle this case and clear things out
        // once another product is added, a new cart will be created
        const cartItems = joinLineItems(cart.line_items);
        const cartItemsAfterRemoval = cartItems.filter((item) => !line_items.find((line_item) => line_item.id === item.id));
        if (cartItemsAfterRemoval.length === 0) {
          setCart(null);
          setShipping(null);
          setBilling(null);
          setCheckout({ id: null });
          setLoading((prev) => ({ ...prev, cart: false }));
        } else {
          await Promise.all([util.cart.load(), util.checkout.load()]);
        }
      },
    },
    checkout: {
      load: async (checkoutId) => {
        setLoading((prev) => ({ ...prev, checkout: true }));
        const checkoutQuery = `?include=consignments.available_shipping_options`;
        const data = await DataProvider.Checkouts.CheckoutsByCheckoutIdGet(store, checkout?.id || checkoutId, checkoutQuery);
        setCheckout({ ...checkout, ...data });
        setLoading((prev) => ({ ...prev, checkout: false }));
        return data;
      },
      updateBilling: async (address) => {
        if (checkout?.id) {
          setLoading((prev) => ({ ...prev, checkout: true }));
          const addressId = checkout?.billing_address?.id;
          if (addressId) {
            await DataProvider.Checkouts.CheckoutsBillingAddressByCheckoutIdAndAddressIdPut(store, checkout?.id, addressId, address);
          } else {
            await DataProvider.Checkouts.CheckoutsBillingAddressByCheckoutIdPost(store, checkout?.id, address);
          }
          await util.checkout.load();
        }
      },
      applyCoupon: async (data) => {
        let errorReturn = "";
        if (checkout?.id) {
          try {
            await DataProvider.Checkouts.CheckoutsCouponsByCheckoutIdPost(store, checkout?.id, data);
          } catch (ex) {
            errorReturn = "Invalid coupon code. Please try again.";
            return errorReturn;
          }
          await util.checkout.load();
          return errorReturn;
        }
      },
      removeCoupon: async (couponCode) => {
        if (checkout?.id) {
          await DataProvider.Checkouts.CheckoutsCouponsByCheckoutIdAndCouponCodeDelete(store, checkout?.id, couponCode);
          await util.checkout.load();
        }
      },
    },
    consignment: {
      create: async (address) => {
        const line_items = combineLineItems(cart.line_items);
        if (line_items.length > 0) {
          setLoading((prev) => ({ ...prev, checkout: true }));
          await DataProvider.Checkouts.CheckoutsConsignmentsByCheckoutIdPost(store, checkout?.id, [
            { address, line_items: combineLineItems(cart.line_items), shippingAddress: address },
          ]);
          await util.checkout.load();
        }
      },
      update: async (consignmentId, consignment) => {
        setLoading((prev) => ({ ...prev, checkout: true }));
        await DataProvider.Checkouts.CheckoutsConsignmentsByCheckoutIdAndConsignmentIdPut(store, checkout?.id, consignmentId, consignment);
        await util.checkout.load();
      },
      delete: async (consignmentId) => {
        setLoading((prev) => ({ ...prev, checkout: true }));
        await DataProvider.Checkouts.CheckoutsConsignmentsByCheckoutIdAndConsignmentIdDelete(store, checkout?.id, consignmentId);
      },
    },
    order: {
      create: async (paymentData) => {

        // Get payment and order Data
        const data = paymentData.order;

        // update the order
        await DataProvider.OrdersV2.UpdateAnOrder(store, data.id, {
          status_id: 11, // Awaiting Fulfillment
          payment_method: "Credit Card",
          payment_provider_id: paymentData.transactionId,
        });

        // attach the order to the payment audit log
        await api.post("/api/checkout/confirmation", {
          orderId: data.id,
          transactionId: paymentData.transactionId,
        });

        // get the order and populate the relations
        let order = await DataProvider.OrdersV2.GetAnOrder(store, data.id);
        await DataProvider.Custom.PopulateOrderRelations(store, order);
        setOrder(data);
        setOrderId(order.id);
      },
      createTempOrder: async () => {
        // create an order
        const data = await DataProvider.Checkouts.CreateAnOrder(store, checkout?.id);
        return data;
      },
      createNoPayment: async () => {
        // create an order
        const data = await DataProvider.Checkouts.CreateAnOrder(store, checkout?.id);

        // update the order
        await DataProvider.OrdersV2.UpdateAnOrder(store, data.id, {
          status_id: 11, // Awaiting Fulfillment
          payment_method: "Manual",

        });

        // attach the order to the payment audit log
        await api.post("/api/checkout/confirmation", {
          orderId: data.id,
        });

        // get the order and populate the relations
        let order = await DataProvider.OrdersV2.GetAnOrder(store, data.id);
        await DataProvider.Custom.PopulateOrderRelations(store, order);
        setOrder(data);
        setOrderId(order.id);
      },
    },
  };
  return util;
};
