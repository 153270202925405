import { useAuth } from "core/hooks/useAuth";
import { useStore } from "core/hooks/useStore";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { StoreLinks, AllLinks } from "./Header";
import { DisabledFeature, ProtectedMessage } from "./MJA";

export const useFeature = () => {
  const { store } = useStore();
  const { user } = useAuth();

  const userLinks = useMemo(
    () => [...StoreLinks(store, user)].concat(StoreLinks(store, user).reduce((acc, o) => [...acc, ...(o.children || [])], [])),
    [store, user]
  );
  const allLinks = useMemo(() => [...AllLinks].concat(AllLinks.reduce((acc, o) => [...acc, ...(o.children || [])], [])), []);

  const enabled = useMemo(
    () =>
      userLinks.reduce((acc, o) => {
        if (o.feature) acc[o.feature] = true;
        return acc;
      }, {}),
    [userLinks, store]
  );

  const desc = useMemo(
    () =>
      allLinks.reduce((acc, o) => {
        if (o.feature) acc[o.feature] = o.desc;
        return acc;
      }, {}),
    [allLinks, store]
  );

  const name = useMemo(
    () =>
      allLinks.reduce((acc, o) => {
        if (o.feature) acc[o.feature] = o.text;
        return acc;
      }, {}),
    [allLinks, store]
  );

  const admin = useMemo(
    () =>
      allLinks.reduce((acc, o) => {
        if (o.feature) acc[o.feature] = o.href.includes("/admin");
        return acc;
      }, {}),
    [allLinks, store]
  );

  return {
    enabled,
    desc,
    name,
    admin,
    all: userLinks,
  };
};

/**
 * @param {{ name: string, redirect?: string, renderInstead?: any, explain?: boolean, children: any  }} props - The props of the component.
 */
export default function Feature(props) {
  const features = useFeature();
  const router = useRouter();
  const { loading, user } = useAuth();

  const { name, redirect, renderInstead, explain, children } = props;

  if (user?.email === "domains@mojoactive.com" && (features.enabled[name] || (!features.enabled[name] && features.admin[name])))
    return props.children || null;

  if (loading || !user || Object.keys(features.enabled).length === 0) return null;

  if (!features.enabled[name] && redirect) {
    router.push(redirect);
    return <div></div>;
  }

  if (!features.enabled[name] && renderInstead) {
    return renderInstead;
  }

  if (features.enabled[name]) {
    return <>{children}</>;
  }

  if (!features.enabled[name] && explain) {
    return <DisabledFeature explain={true} />;
  }

  if (explain && user && !loading) {
    return <ProtectedMessage explain={true} />;
  }

  return null;
}

/**
 * @param {{ feature: string, permission: string, children: any, explain: boolean  }} props - The props of the component.
 */
export function FeaturePermission(props) {
  const { user, loading } = useAuth();

  const { name, permission, children, explain } = props;

  if (loading || !user) return null;

  if (user.role === "admin") return children || null;

  const permissions = user?.meta?.featurePermissions || {};
  const permissionsForFeature = permissions[name] || {};
  const hasAccess = permissionsForFeature[permission] || false;

  return hasAccess ? <>{children}</> : explain ? <ProtectedMessage explain={true} /> : null;
}
