import axios from "axios";
import fetcher from "core/utils/fetcher";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocalStorage } from "react-use";
import useSWR, { useSWRConfig } from "swr";
import { useAuth } from "./useAuth";

const StoreContext = createContext();

/**
 * @returns {useStoreContext}
 */
export const useStore = () => useContext(StoreContext);

class API {
  #axios = null;
  constructor(store, account) {
    this.#axios = axios.create({
      headers: {
        "X-Store-Hash": store?.storeHash,
        "X-Account-Id": account?.id,
      },
    });
  }
  async get(path, options) {
    return (await this.#axios.get(path, options)).data;
  }
  async post(path, data, options) {
    return (await this.#axios.post(path, data, options)).data;
  }
  async put(path, data, options) {
    return (await this.#axios.put(path, data, options)).data;
  }
  async delete(path, options) {
    return (await this.#axios.delete(path, options)).data;
  }
}

export function StoreProvider(props) {
  const { mutate } = useSWRConfig();
  const { user } = useAuth();
  const { data: context } = useSWR(user ? "/api/store/context" : null, fetcher, { revalidateOnFocus: false });
  const { data: stores } = useSWR(user ? "/api/store" : null, fetcher, { revalidateOnFocus: false });
  const [store, setStore] = useState();
  const [account, setAccount] = useLocalStorage(`local-account-${context?.sessionId}`);
  const lastStoreHash = useRef();

  useEffect(() => {
    if (store) cacheStore(store);

    // If the store changes, we need to revalidate the context
    if (store?.storeHash && lastStoreHash.current && lastStoreHash.current !== store?.storeHash) {
      mutate("/api/store/context");
    }

    // keep track of the last store hash so we can revalidate the context when it changes
    lastStoreHash.current = store?.storeHash;
  }, [store]);

  useEffect(() => {
    if (!store && stores?.length) {
      setStore(stores.find((o) => o.id == context?.id));
    }
  }, [stores, context]);

  const cacheStore = (store) => {
    window.mja = window.mja || {};
    window.mja.selectedStore = store;
  };

  const handleSetStore = useCallback(
    async (data) => {
      cacheStore(data);
      setStore(data);
      props?.onChange?.(data);
    },
    [setStore, props]
  );

  const handleSetAccount = useCallback(
    async (data) => {
      setAccount(data);
      props?.onChange?.(data);
    },
    [setAccount, props]
  );

  const storeData = useMemo(() => {
    return {
      api: new API(store, account),
      context: context,
      stores: stores,
      store,
      account,
      setStore: handleSetStore,
      setAccount: handleSetAccount,
      mutate: async (key) => {
        switch (key) {
          case "context":
            await mutate("/api/store/context");
            break;
          case "stores":
            const data = await mutate("/api/store");
            if (data?.length && store) {
              handleSetStore(data.find((o) => o.id == store?.id));
            }
            break;
        }
      },
    };
  }, [store, account]);

  return <StoreContext.Provider value={storeData}>{props.children}</StoreContext.Provider>;
}

/**
 * @typedef {Object} useStoreContext
 * @property {import('axios').AxiosInstance} api
 * @property {StoreContext} context
 * @property {import("@prisma/client").Store[]} stores
 * @property {import("@prisma/client").Store} store
 * @property {(store: import("@prisma/client").Store) => void} setStore
 * @property {(key: "context" | "stores") => void} mutate
 * @property {import("@prisma/client").Account} account
 * @property {(account: import("@prisma/client").Account) => void} setAccount
 */
