import { Popover, Transition } from "@headlessui/react";
import { classNames } from "@mojoactive/components";
import {
  IconArrowRampRight2,
  IconArrowsLeftRight,
  IconBook,
  IconBuildingStore,
  IconCalendarTime,
  IconChevronDown,
  IconCode,
  IconCodeDots,
  IconCreditCard,
  IconDatabaseCog,
  IconFiles,
  IconHeartRateMonitor,
  IconHome,
  IconKey,
  IconListSearch,
  IconLockAccess,
  IconLogout,
  IconPackage,
  IconPuzzle,
  IconSettings,
  IconShoppingBagPlus,
  IconShoppingCart,
  IconShoppingCartPlus,
  IconSourceCode,
  IconStackPush,
  IconTags,
  IconTextPlus,
  IconUsers,
  IconWebhook,
  IconZoomInArea,
} from "@tabler/icons-react";
import { useAuth } from "core/hooks/useAuth";
import { useMediaQuery } from "core/hooks/useMediaQuery";
import { useStore } from "core/hooks/useStore";
import classnames from "core/utils/classnames";
import { cloneDeep } from "lodash";
import { signOut, useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { StoreConnection } from "./Debugging/StoreConnection";
import Logo from "./Logo";

export const AllLinks = [
  {
    text: "Dashboard",
    href: "/",
    icon: <IconHome className="h-10 w-10 flex-shrink-0 text-orange-600" />,
    feature: "dashboard",
    desc: "The landing page with high-level overviews and links to commonly used screens.",
  },
  {
    text: "Audit Log",
    href: "/audit-log",
    icon: <IconListSearch className="h-10 w-10 flex-shrink-0 text-orange-600" />,
    desc: "See a record of webhooks, scheduled tasks and other custom events.",
    feature: "auditLog",
  },
  {
    text: "Documentation",
    href: "/docs",
    icon: <IconBook className="h-10 w-10 flex-shrink-0 text-orange-600" />,
    desc: "Find information about your storefront and custom integrations.",
    feature: "docs",
  },
  {
    text: "Store Management",
    children: [
      {
        href: "/merchant-checkout",
        text: "Merchant Checkout",
        desc: "Place orders on behalf of your customers.",
        feature: "merchant-checkout",
        icon: <IconShoppingBagPlus className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/widgets",
        text: "Widget Editor",
        desc: "Author custom Page Builder Widgets, edit Schema, Templates and even GraphQL queries.",
        feature: "widgets",
        icon: <IconCode className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/compare",
        text: "Compare Stores",
        desc: "Compare and update settings across multiple storefronts.",
        feature: "storeCompare",
        icon: <IconArrowsLeftRight className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/explore",
        text: "Data Explorer",
        desc: "View and export data directly from the BigCommerce Management API's.",
        feature: "explorer",
        icon: <IconZoomInArea className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/checkout",
        text: "Custom Checkout Manager",
        desc: "Manage all versions of custom checkout and quickly switch between them.",
        feature: "checkoutManager",
        icon: <IconShoppingCart className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/redirects",
        text: "Redirects",
        desc: "Configure custom 301 redirects, friendly URLs, and QR codes for your storefront.",
        feature: "redirects",
        icon: <IconArrowRampRight2 className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/nav-builder",
        text: "Navigation Builder",
        desc: "Create and manage custom navigation menus for your storefront.",
        feature: "nav-builder",
        icon: <IconTextPlus className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/admin/file-manager",
        icon: <IconFiles className="h-10 w-10 flex-shrink-0 text-orange-600" />,
        text: "File Manager",
        feature: "fileManager",
        desc: "Manage all files uploaded to or generated by the app.",
      },
      {
        href: "/admin/users",
        text: "User Management",
        desc: "Manage users and their permissions across your BigCommerce stores.",
        feature: "userManagement",
        icon: <IconUsers className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/source-codes",
        text: "Source Codes",
        desc: "Track the source of your orders and customers with custom source codes.",
        feature: "sourceCodes",
        icon: <IconTags className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/settings?teb=savedCarts",
        text: "Saved Carts",
        desc: "Save customer carts for an extended period of time.",
        feature: "savedCarts",
        icon: <IconShoppingCartPlus className="h-10 w-10 flex-shrink-0 text-orange-600" />,
        hidden: true,
      },
      {
        href: "/settings",
        text: "Settings",
        desc: "Manage global settings for all features of this platform.",
        feature: "settings",
        icon: <IconSettings className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
    ],
  },
  {
    text: "Automation",
    children: [
      {
        href: "/monitor",
        text: "Store Monitor",
        feature: "storeMonitor",
        desc: "Receive alerts when hourly thresholds are not met for orders, based on volume and/or revenue.",
        icon: <IconHeartRateMonitor className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/store-pickup",
        text: "Store Pickup (BOPIS)",
        feature: "storePickup",
        desc: "Manage in store pickup time slots and availability.",
        icon: <IconPackage className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/webhooks",
        text: "Webhooks",
        desc: "Register and Monitor Webhooks in your BigCommerce Storefront.",
        feature: "webhooks",
        icon: <IconWebhook className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/tasks",
        text: "Scheduled Tasks",
        desc: "Trigger critical functionality around fulfillment logistics, inventory & product management.",
        feature: "scheduledTasks",
        icon: <IconCalendarTime className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/payment-putty",
        text: "PaymentPutty",
        desc: "Quickly connect BigCommerce to your physical gift card provider or custom payment system.",
        feature: "paymentPutty",
        icon: <IconCreditCard className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/admin/endpoints",
        text: "Flex Endpoints",
        desc: "Create lightweight API's without a code editor.",
        feature: "flexEndpoints",
        icon: <IconSourceCode className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/admin/integrations",
        text: "Integrations",
        desc: "Connect to third-party services and apps.",
        feature: "integrations",
        icon: <IconPuzzle className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
      {
        href: "/admin/events",
        text: "Events",
        desc: "View custom events and manage outgoing notifications.",
        feature: "events",
        icon: <IconStackPush className="h-10 w-10 flex-shrink-0 text-orange-600" />,
      },
    ],
  },
];

export const StoreLinks = (store, user) => {
  if (!store) return [];
  const userFeatures = user?.meta?.features || {};
  let enabledLinks = cloneDeep(AllLinks).filter((link) => {
    if (link.feature) {
      let isEnabled = store.meta?.features?.[link.feature];
      let hasAccess = user && user?.role !== "admin" ? userFeatures[link.feature] : true;
      return isEnabled && hasAccess;
    }
    if (link.children) {
      link.children = link.children.filter((child) => {
        let isEnabled = store.meta?.features?.[child.feature];
        let hasAccess = user && user?.role !== "admin" ? userFeatures[child.feature] : true;
        return isEnabled && hasAccess;
      });
    }
    if (link.children?.length == 0) return false;
    return true;
  });

  return enabledLinks;
};

const inactive_className = `flex-shrink-0 border-transparent text-gray-600 hover:border-orange-500 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium focus:outline-none group`;
const active_className = `flex-shrink-0 border-orange-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium group`;

export default function Header({ links, logo, logoClass, action }) {
  const router = useRouter();
  const { data } = useSession();
  const { context, store } = useStore();
  const { user } = useAuth();

  const headerLinks = () => {
    if (!store) return [];
    return StoreLinks(store, user)
      .filter((link) => {
        if (link.feature) return store.meta?.features?.[link.feature];
        if (link.children) {
          link.children = link.children.filter((child) => store.meta?.features?.[child.feature] && !child.hidden);
        }
        if (link.children?.length == 0) return false;
        return true;
      })
      .concat(Array.isArray(links) ? links : []);
  };

  const isMJA = user?.email?.includes("domains@mojoactive.com");

  return (
    <nav className="main-navigation h-[65px] border-b bg-white">
      <div className="mx-auto max-w-7xl px-4">
        <div className="relative flex h-16 justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden"></div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="hidden flex-shrink-0 sm:flex sm:space-x-3">
              <FlyoutMenu
                align="left"
                indicator={false}
                styled={false}
                disabled={!isMJA}
                text={
                  logo ? (
                    <img
                      src={logo || `/favicon.ico`}
                      alt="MoJo Active"
                      className={classNames("my-auto mr-2 block h-8 w-8 flex-shrink-0", logoClass || "")}
                    />
                  ) : (
                    <Logo className={"mr-2 w-[95px]"} />
                  )
                }
                // eslint-disable-next-line react/no-children-prop
                children={[
                  {
                    href: "/admin/stores",
                    icon: <IconBuildingStore className="h-10 w-10 flex-shrink-0 text-orange-600" />,
                    text: "Storefronts",
                    desc: "Manage the connected BigCommerce stores.",
                  },
                  {
                    href: "/admin/secrets",
                    icon: <IconKey className="h-10 w-10 flex-shrink-0 text-orange-600" />,
                    text: "Secrets",
                    desc: "Manage the secrets used by the app.",
                  },
                  {
                    href: "/admin/endpoints",
                    icon: <IconSourceCode className="h-10 w-10 flex-shrink-0 text-orange-600" />,
                    text: "Flex Endpoints",
                    desc: "Create lightweight API's without a code editor.",
                  },
                  {
                    href: "/admin/integrations",
                    icon: <IconPuzzle className="h-10 w-10 flex-shrink-0 text-orange-600" />,
                    text: "Integrations",
                    desc: "Connect to third-party services and apps.",
                  },
                  {
                    href: "/admin/api",
                    icon: <IconLockAccess className="h-10 w-10 flex-shrink-0 text-orange-600" />,
                    text: "API Credentials",
                    desc: "Control API access to the platform.",
                  },
                  {
                    href: "/admin/scripts",
                    icon: <IconCodeDots className="h-10 w-10 flex-shrink-0 text-orange-600" />,
                    text: "Script Manager",
                    desc: "Create and deploy custom javascript bundles.",
                  },
                  {
                    href: "/admin/datastore",
                    icon: <IconDatabaseCog className="h-10 w-10 flex-shrink-0 text-orange-600" />,
                    text: "DataStore",
                    desc: "View and manage local data storage.",
                  },
                  {
                    href: "/admin/users",
                    icon: <IconUsers className="h-10 w-10 flex-shrink-0 text-orange-600" />,
                    text: "User Management",
                    desc: "Manage users and their permissions across stores.",
                  },
                  {
                    href: "/admin/settings",
                    icon: <IconSettings className="h-10 w-10 flex-shrink-0 text-orange-600" />,
                    text: "Settings",
                    desc: "Global platform settings.",
                  },
                ]}
              />

              {headerLinks().map((o, i) => (
                <Fragment key={i}>
                  {o.children ? (
                    <FlyoutMenu key={i} {...o} />
                  ) : o.target ? (
                    <a
                      href={o.href}
                      target={o.target}
                      className={
                        router.pathname == "/" && o.href == "/"
                          ? active_className
                          : router.asPath.startsWith(o.href) && o.href != "/"
                          ? active_className
                          : inactive_className
                      }
                    >
                      {o.text}
                    </a>
                  ) : (
                    <Link
                      key={i}
                      href={o.href}
                      className={
                        router.pathname == "/" && o.href == "/"
                          ? active_className
                          : router.asPath.startsWith(o.href) && o.href != "/"
                          ? active_className
                          : inactive_className
                      }
                    >
                      {o.text}
                    </Link>
                  )}
                </Fragment>
              ))}
            </div>
            <div className="ml-auto hidden items-center sm:flex sm:items-stretch sm:space-x-6">
              {action && <div className="flex h-full items-center">{action}</div>}

              {data?.user && (
                <a
                  href="#"
                  className={inactive_className}
                  onClick={(e) => {
                    e.preventDefault();
                    signOut({ callbackUrl: "/auth/signin" });
                  }}
                >
                  <IconLogout className="mr-1" />
                  Sign Out
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <StoreConnection context={context} />
    </nav>
  );
}

const FlyoutMenu = ({ text, align = "center", indicator = true, styled = true, disabled, children }) => {
  const router = useRouter();
  // if this popover extends beyond the right edge of the screen, align it to the right
  const alignRight = useMediaQuery("(max-width: 1200px)");

  if (disabled) {
    return (
      <button
        className={
          styled
            ? children.some((o) => router.asPath.startsWith(o.href) && o.href != "/")
              ? active_className
              : inactive_className
            : "cursor-default"
        }
      >
        <span>{text}</span>
      </button>
    );
  }

  return (
    <Popover className="relative flex items-stretch">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={
              styled
                ? children.some((o) => router.asPath.startsWith(o.href) && o.href != "/")
                  ? active_className
                  : inactive_className
                : "focus:outline-none focus-visible:outline-none"
            }
          >
            <span>{text}</span>
            {indicator && (
              <IconChevronDown
                className={classnames(open ? "text-gray-600" : "text-gray-400", "ml-1 h-4 w-4 group-hover:text-gray-600")}
                aria-hidden="true"
              />
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={classNames(
                "absolute z-50 mt-14 w-screen max-w-md transform px-2 sm:px-0",
                align == "right" ? "right-0" : "",
                align == "center" ? "left-1/2 -translate-x-1/2" : "",
                alignRight ? "right-0" : ""
              )}
            >
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                  {children.map((item, i) => (
                    <Link
                      key={i}
                      href={item.href}
                      className="-m-3 flex items-center rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                      onClick={() => close()}
                    >
                      {item.icon}
                      <div className="ml-4">
                        <p className="flex items-center space-x-2 text-base font-medium text-gray-900">
                          {item.text}
                          {item.badge && (
                            <span
                              className={classNames(
                                item.badgeClassName,
                                "ml-2 inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset"
                              )}
                            >
                              {item.badge}
                            </span>
                          )}
                        </p>
                        <p className="mt-1 text-sm text-gray-500">{item.desc}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
