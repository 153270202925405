import { Input, SelectMenu, classNames, useForm } from "@mojoactive/components";
import { cloneDeep } from "lodash";
import { useDebounce } from "react-use";
import { useMerchantCheckout } from "./useMerchantCheckout";

export const StepHeader = ({ number, title, action, small }) => {
  return (
    <div className="mb-3 flex items-center text-xl font-semibold">
      {number && (
        <div className="flex h-9 w-9 items-center justify-center rounded-full border border-gray-300 bg-gray-200 text-lg font-bold">{number}</div>
      )}
      <div className={classNames(number ? "ml-3" : "", small ? "text-lg" : "")}>{title}</div>
      {action && <div className="ml-auto">{action}</div>}
    </div>
  );
};

export const AddressForm = ({ address, type, hideFields = [], additional, onChange }) => {
  const { config } = useMerchantCheckout();

  const { values, errors, setValue } = useForm({
    initialValues: { ...(address || {}), country_code: "US" },
    validation: {
      first_name: { required: true },
      last_name: { required: true },
      address1: { required: hideFields.includes("address1") ? false : true },
      city: { required: hideFields.includes("city") ? false : true },
      postal_code: { required: hideFields.includes("postal_code") ? false : true },
      state_or_province_code: { required: hideFields.includes("state_or_province") ? false : true },
      phone: {
        message: "Please enter a valid phone number",
        pattern: /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/,
      },
    },
  });

  useDebounce(() => onChange(values), 500, [values]);
  console.log(errors);
  const label = (field, fallback) => {
    return config?.[type]?.lang?.[field] || config?.lang?.[field] || fallback;
  };

  return (
    <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4">
      <Input
        label={label("first_name", "First Name")}
        id="first_name"
        name="first_name"
        autoComplete="given-name"
        value={values.first_name}
        onChange={(val) => setValue("first_name", val)}
        error={errors.first_name}
      />
      <Input
        label={label("last_name", "Last Name")}
        id="last_name"
        name="last_name"
        value={values.last_name}
        onChange={(val) => setValue("last_name", val)}
        error={errors.last_name}
      />
      {additional?.includes("email") && (
        <div className="sm:col-span-2">
          <Input
            label={label("email", "Email")}
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            value={values.email}
            onChange={(val) => setValue("email", val)}
            error={errors.email}
          />
        </div>
      )}
      {!hideFields.includes("company") && (
        <div className="sm:col-span-2">
          <Input
            label={label("company", "Company")}
            id="company"
            name="company"
            value={values.company}
            onChange={(val) => setValue("company", val)}
            error={errors.company}
          />
        </div>
      )}
      {!hideFields.includes("phone") && (
        <div className="sm:col-span-2">
          <Input
            label={label("phone", "Phone")}
            type="text"
            name="phone"
            id="phone"
            autoComplete="tel"
            value={values.phone}
            onChange={(val) => setValue("phone", val)}
            error={errors.phone}
          />
        </div>
      )}
      {!hideFields.includes("address1") && (
        <div className="sm:col-span-2">
          <Input
            label={label("address1", "Address")}
            id="address1"
            name="address1"
            value={values.address1}
            onChange={(val) => setValue("address1", val)}
            error={errors.address1}
          />
        </div>
      )}
      {!hideFields.includes("address2") && (
        <div className="sm:col-span-2">
          <Input
            label={label("address2", "Apartment, suite, etc.")}
            type="text"
            id="address2"
            name="address2"
            value={values.address2}
            onChange={(val) => setValue("address2", val)}
          />
        </div>
      )}
      {!hideFields.includes("city") && (
        <div className="sm:col-span-2">
          <Input
            label={label("city", "City")}
            id="city"
            name="city"
            value={values.city}
            onChange={(val) => setValue("city", val)}
            error={errors.city}
          />
        </div>
      )}
      {/* <div>
        <SelectMenu
          label={label("country_code", "Country")}
          id="country"
          name="country"
          autoComplete="country-name"
          options={countries?.map((o) => ({ value: o.country_iso2, text: o.country }))}
          value={values.country_code}
          onChange={(item) => setValue("country_code", item.value)}
          error={errors.country_code}
        ></SelectMenu>
      </div> */}
      {!hideFields.includes("state_or_province") && (
        <div className="sm:col-span-2">
          {config?.states?.length > 0 ? (
            <SelectMenu
              label={label("state_or_province_code", "State / Province")}
              type="text"
              name="region"
              id="region"
              autoComplete="region"
              options={config?.states?.map((o) => ({ value: o.state_abbreviation, text: o.state }))}
              value={values.state_or_province_code}
              onChange={(item) => {
                setValue("state_or_province_code", item.value);
                setValue("state_or_province", "");
              }}
              error={errors.state_or_province_code}
            />
          ) : (
            <Input
              label={label("state_or_province", "State / Province")}
              id="region"
              name="region"
              value={values.state_or_province}
              onChange={(val) => {
                setValue("state_or_province", val);
                setValue("state_or_province_code", "");
              }}
              error={errors.state_or_province}
            />
          )}
        </div>
      )}
      {!hideFields.includes("postal_code") && (
        <div className="sm:col-span-2">
          <Input
            label={label("postal_code", "Postal Code")}
            name="postal-code"
            id="postal-code"
            autoComplete="postal-code"
            value={values.postal_code}
            onChange={(val) => setValue("postal_code", val)}
            error={errors.postal_code}
          />
        </div>
      )}
    </div>
  );
};

export const updateProductOptionModifiers = (existingProducts, index, option) => {
  let products = cloneDeep(existingProducts);
  products[index].option_selections = products[index].option_selections || [];
  let foundIndex = products[index].option_selections.findIndex((o) => o.option_id == option.id);

  let lineItemOption = {
    option_id: option.id,
    option_value: option?.selection?.id || option?.selection?.label,
  };

  const isPickList = option?.selection?.value_data?.product_id;
  if (isPickList) {
    lineItemOption.product_id = option.selection.value_data.product_id;
    lineItemOption.quantity = 1;
  }

  if (foundIndex > -1 && !option.keep) {
    products[index].option_selections[foundIndex] = lineItemOption;
  } else {
    if (isPickList) {
      let foundOptionValueIndex = products[index].option_selections.findIndex((o) => o.option_value == option.selection.id);
      if (foundOptionValueIndex > -1) {
        products[index].option_selections = products[index].option_selections.filter((o) => o.option_value !== option.selection.id);
      } else {
        products[index].option_selections.push(lineItemOption);
      }
    } else {
      products[index].option_selections.push(lineItemOption);
    }
  }

  if (!option?.selection) {
    foundIndex = products[index].option_selections.findIndex((o) => o.option_id == option.id);
    products[index].option_selections.splice(foundIndex, 1);
  }

  return products;
};

export const updateProductProp = (existingProducts, index, prop, value) => {
  let products = cloneDeep(existingProducts);
  products[index][prop] = value;
  return products;
};

export const getVariant = (o) => {
  let hasOptions = o?.option_selections?.length > 0;
  if (hasOptions) {
    let variant = o.variants.find((v) => {
      let variantOptionIds = v.option_values?.map((o) => o.id) || [];
      let selectedOptionIds = o.option_selections?.map((o) => o.option_value);
      return variantOptionIds.every((id) => selectedOptionIds.indexOf(id) > -1);
    });
    if (variant) {
      return variant;
    }
  }
  return null;
};

export const getProductPrice = (o, prop = "sale_price") => {
  let price = (o[prop] || o.price || o.list_price || o.price_ex_tax) * (o.qty || 1);

  // factor in variant options
  let variant = getVariant(o);
  if (variant) price = (variant[prop] || variant.price || price) * (o.qty || 1);
  return price;
};

export const isSameAddress = (first = {}, second = {}, bIncludeState) => {
  let fields = ["address1", "address2", "city", "postal_code"];

  if (bIncludeState) {
    fields = [...fields, "state_or_province_code"];
  }

  const hash1 = fields
    .map((field) => first?.[field])
    .join("")
    .replace(/\s/g, "")
    .toLowerCase();
  const hash2 = fields
    .map((field) => second?.[field])
    .join("")
    .replace(/\s/g, "")
    .toLowerCase();

  return hash1 === hash2;
};

export const isValidAddress = (address = {}) => {
  const requiredFields = ["first_name", "last_name", "address1", "city", "country_code", "postal_code"];
  return requiredFields.every((field) => address?.[field]);
};

export const formatLineItems = (products) => {
  return products.map((o) => {
    if (o.option_selections) {
      return {
        product_id: o.id,
        quantity: o.quantity || 1,
        option_selections: o.option_selections,
      };
    } else {
      return {
        product_id: o.id,
        quantity: o.quantity || 1,
      };
    }
  });
};

export const combineLineItems = (lineItems) => {
  return [...(lineItems?.custom_items || []), ...(lineItems?.physical_items || [])].map((o) => ({
    item_id: o.id,
    quantity: o.quantity,
  }));
};

export const joinLineItems = (lineItems) => {
  return [...(lineItems?.custom_items || []), ...(lineItems?.physical_items || []), ...(lineItems?.digital_items || [])];
};
